<template>
  <div>
    <div class="page-content">
      <div class="page-sub-title flex flex-center">
        <span class="flex-1">筛选查询</span>
      </div>
      <div>
        <div class="search-item">
          <span class="label">小程序名称/简称</span>
          <el-input v-model="searchParams.search" size="small" clearable style="width: 240px" placeholder="请输入小程序名称"></el-input>
        </div>
        <div class="search-item">
          <span class="label">门店名称/简称</span>
          <el-input v-model="searchParams.hotelNameSearch" size="small" clearable style="width: 240px" placeholder="请输入门店名称"></el-input>
        </div>
        <div class="search-item">
          <span class="label">所属直销</span>
          <el-select
            v-model="searchParams.salerId"
            filterable
            remote
            :remote-method="remoteMethod"
            :loading="selectLoading"
            placeholder="请选择"
            size="small"
            style="width: 160px" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in salerList"
              :key="item.id"
              :label="item.realName"
              :value="item.userId">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <span class="label">是否测试小程序</span>
          <el-select v-model="searchParams.testFlag" placeholder="请选择" size="small" style="width: 160px" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option label="正式小程序" :value="false"></el-option>
            <el-option label="测试小程序" :value="true"></el-option>
          </el-select>
        </div>
        <div class="search-item">
          <el-button type="primary" size="small" @click="search">查询</el-button>
          <el-button class="border-btn" plain size="small" @click="reset">重置</el-button>
        </div>
      </div>
      <div class="page-sub-title flex flex-center">
        <span class="flex-1">小程序列表</span>
        <el-button type="primary" size="small" @click="goEdit()">小程序开户</el-button>
        <el-button class="border-btn" plain size="small" @click="codeVisible = true">代码库管理</el-button>
      </div>
      <el-table :data="list" style="width: 100%" border header-row-class-name="table-header">
        <el-table-column align="center" label="小程序名称" min-width="120" fixed="left">
          <template slot-scope="scope">
            <el-button type="text" @click="goEdit(scope.row)">{{scope.row.name}}</el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="shortName" label="小程序简称" width="130"></el-table-column>
        <el-table-column align="center" prop="orgName" label="所属机构"></el-table-column>
        <el-table-column align="center" prop="relationOrgInfoList" label="关联门店" min-width="200">
          <template slot-scope="scope">
            <div class="text-left">
              <div v-for="(n,i) in scope.row.relationOrgInfoList" :key="i" v-show="i < 4">{{n.hotelShortName || n.hotelName}}</div>
              <el-button class="p-0" type="text" @click="handleCommand('relation', scope.row)" v-show="scope.row.relationOrgInfoList.length > 4">
                查看全部（{{scope.row.relationOrgInfoList.length}}）>
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态" width="100">
          <template slot-scope="scope">
            <span class="status-tag">
              <span :class="'status' + scope.row.status"></span>
              {{statusMap[scope.row.status]}}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="初装费(元)" width="120">
          <template slot-scope="scope">
            <span>{{$commaNum(scope.row.firstPrice)}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="年费(元/年)" width="120">
          <template slot-scope="scope">
            <span>{{$commaNum(scope.row.yearPrice)}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="salerName" label="所属直销"></el-table-column>
        <el-table-column align="center" prop="enableTime" label="启用时间" width="100"></el-table-column>
        <el-table-column align="center" prop="expireTime" label="到期时间" width="100"></el-table-column>
        <el-table-column align="center" label="是否授权成功" width="120">
          <template slot-scope="scope">
            {{scope.row.authorizedFlag ? '是' : '否'}}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="authorizedTime" label="授权时间" width="100"></el-table-column>
        <el-table-column align="center" prop="createTime" label="开户时间" width="100"></el-table-column>
        <el-table-column
          align="center"
          label="是否测试小程序"
          width="120"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.testFlag !== null">{{scope.row.testFlag === true ? '测试小程序' : '正式小程序'}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="handleCommand('recharge', scope.row)">续期</el-button>
            <el-dropdown trigger="click" @command="handleCommand($event, scope.row)">
              <span class="el-dropdown-link">
                <el-button type="text" style="margin-left: 10px;">更多操作<i class="el-icon-arrow-down el-icon--right"></i></el-button>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="clear" :class="{'disabled': auth.indexOf('minip:clear:btn') < 0 || scope.row.clearBizDataFlag}">
                  <el-tooltip class="item" :content="auth.indexOf('minip:clear:btn') < 0 ? '您未有该权限，如需开通，请联系管理员' : '历史已操作过数据清空，不可进行二次操作'" placement="left" :disabled="auth.indexOf('minip:clear:btn') >= 0 && !scope.row.clearBizDataFlag">
                    <span>
                      <span class="icon-login"></span>
                      清空业务数据
                    </span>
                  </el-tooltip>
                </el-dropdown-item>
                <el-dropdown-item command="delete" v-if="scope.row.testFlag" :class="{'disabled': auth.indexOf('minip:delete:btn') < 0}">
                  <el-tooltip class="item" content="您未有该权限，如需开通，请联系管理员" placement="left" :disabled="auth.indexOf('minip:delete:btn') >= 0">
                    <span>
                      <span class="icon-login"></span>
                      删除测试小程序
                    </span>
                  </el-tooltip>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        class="text-right m-t-16"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="pageSizes"
        :current-page="pageParams.pageNo"
        :page-size="pageParams.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems">
      </el-pagination>
    </div>

    <recharge-minip-dialog :visible.sync="rechargeVisible" :info.sync="dialogData" @confirm="getList"></recharge-minip-dialog>

    <code-template-dialog :visible.sync="codeVisible" type="normal"></code-template-dialog>

    <!-- 小程序关联门店 弹窗 -->
    <el-dialog
      :visible.sync="relationVisible"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div slot="title">
        <span>关联门店</span>
        <span class="ml-10 grey" v-if="dialogData.relationOrgInfoList">（共{{dialogData.relationOrgInfoList.length}}家）</span>
      </div>
      <div class="flex flex-between flex-wrap">
        <div class="relation-item" v-for="(n, i) in dialogData.relationOrgInfoList" :key="i">{{i + 1}}.{{n.hotelShortName || n.hotelName}}</div>
      </div>
    </el-dialog>

    <!-- 清空业务数据 弹窗 -->
    <el-dialog
      :visible.sync="clearVisible"
      width="800px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="清空业务数据"
    >
      <div>
        <div class="c-red">
          <div>说明：</div>
          <div>1、业务数据清空只有一次机会，清空后不可再次操作清空，请仔细确认后再进行清空</div>
          <div>2、清空后的数据无法恢复</div>
        </div>
        <div class="flex mt-20">
          <span><span class="c-red">*</span>清空数据对象：</span>
          <div>
            <el-checkbox-group v-model="clearData" disabled>
              <el-checkbox label="auth">授权用户信息</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button size="small" @click="clearVisible = false">取消</el-button>
        <el-button type="primary" size="small" @click="handleClear" :disabled="clearData.length == 0">清空</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { pageMixin } from '@/mixins/pageMixin'
import { get_key_value, check_config_key } from '@/api/common'
import { post_getSaleUserList } from "@/api/product";
import { get_minip_list, delete_minip, clear_minip_data } from '@/api/miniprogram'
import rechargeMinipDialog from './component/recharge-minip-dialog'
import codeTemplateDialog from './component/code-template-dialog'

export default {
  name: 'minip-list',
  mixins: [pageMixin],
  components: {
    rechargeMinipDialog,
    codeTemplateDialog,
  },
  data() {
    return {
      statusOption: [],
      statusMap: {},
      salerList: [],
      saler100List: [],
      selectLoading: false,
      searchParams: {
        search: '',
        hotelNameSearch: '',
        salerId: '',
        status: '',
        testFlag: '',
      },
      rechargeVisible: false,
      changeVisible: false,
      codeVisible: false,
      relationVisible: false,
      dialogData: {},
      auth: [], // 权限
      clearVisible: false,
      clearData: ['auth']
    }
  },
  computed: {
  },
  activated() {
    if (this.$store.state.page.keepAlive.indexOf('minip-list') < 0 || this.list.length == 0) {
      Object.assign(this.$data, this.$options.data())
      this.getAuth()
      this.getOption()
      this.getSaler()
      this.getList()
    }
  },
  methods: {
    getAuth() {
      check_config_key({
        configKeyList: ['minip:delete:btn', 'minip:clear:btn'],
        hotelId: 0
      }).then(res => {
        this.auth = res && res.filter(o => { return !!o.hasConfigKey })
                              .map(v => { return v.configKey })
      })
    },
    getOption() {
      get_key_value({
        type: 'HOTEL_STATUS'
      }).then(res => {
        this.statusOption = res ? res.records : []
        this.statusMap = this.$arrayToMap(this.statusOption)
      })
    },
    async getSaler(search) {
      const res = await post_getSaleUserList({
        search,
        size: 100,
        pageNo: 1,
      })
      this.selectLoading = false;
      this.salerList = res ? res.records : []
      if (!search) {
        this.saler100List = this.salerList.concat()
      }
    },
    remoteMethod(query) {
      if (query) {
        this.selectLoading = true;
        setTimeout(() => {
          this.getSaler(query.toLowerCase())
        }, 200);
      } else {
        this.salerList = this.saler100List.concat()
      }
    },
    async getList() {
      let params = {
        ...this.pageParams,
        ...this.searchParams
      }
      const res = await get_minip_list(params)
      console.log(res)
      this.list = res ? res.records : []
      this.totalItems = res ? res.total : 0
    },
    goEdit(row) {
      this.$router.push({
        name: 'minip-edit',
        query: {
          id: row ? row.id : ''
        }
      })
    },
    handleCommand(value, row) {
      let l = row.relationOrgInfoList.filter(o => { return !o.testFlag });
      let str = '';
      switch (value) {
        case 'recharge':
          this.rechargeVisible = true
          this.dialogData = row
          break
        case 'relation':
          this.relationVisible = true
          this.dialogData = row
          break
        case 'clear':
          if (this.auth.indexOf('minip:clear:btn') >= 0 && !row.clearBizDataFlag) {
            this.clearVisible = true
            this.dialogData = row
          }
          break
        case 'delete':
          if (this.auth.indexOf('minip:delete:btn') < 0) {
            return
          }
          if (l.length > 0) {
            str = `该小程序关联了${row.orgType == 2 && !row.orgTestFlag ? '1家正式集团、' : ''}${l.length}家正式门店，删除后，将解除绑定该小程序关联的所属机构，列表将不再显示该小程序，请确认是否删除？`
          } else {
            str = '删除后，将解除绑定该小程序关联的所属机构，列表将不再显示该小程序，请确认是否删除？'
          }
          this.$confirm(str, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            customClass: 'dialog-class',
            center: true,
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true;
                delete_minip({
                  mpId: row.id
                }).then(() => {
                  this.$message.success('删除成功')
                  this.getList()
                  instance.confirmButtonLoading = false;
                  done()
                })
              } else {
                done()
              }
            }
          }).then(action => {
            console.log(action)
          })
          break
      }
    },
    handleClear() {
      this.$confirm(`
        <div>
          <div>1、清空数据后将无法恢复，请谨慎操作</div>
          <div>2、确认清空后，清空数据功能将会置灰，不可二次操作</div>
        </div>
      `, '二次确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'dialog-class',
        dangerouslyUseHTMLString: true,
        center: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            clear_minip_data({
              mpId: this.dialogData.id
            }).then(() => {
              this.clearVisible = false
              this.$message.success('清空成功')
              this.getList()
              instance.confirmButtonLoading = false;
              done()
            })
          } else {
            done()
          }
        }
      }).then(action => {
        console.log(action)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.status-tag{
  & > span{
    width: 6px;
    height: 6px;
    display: inline-block;
    border-radius: 50%;
    vertical-align: 2px;
  }
  .status1{ // 新开户
    background: #5390FF;
  }
  .status2{ // 营业中
    background: #67C23A;
  }
  .status3{ // 已下线
    background: #A4A4A4;
  }
  .status4{ // 异常
    background: #C34D4D;
  }
}
.icon-login{
  width: 11px;
  height: 13px;
  display: inline-block;
  background: url('../../assets/icons/icon-login1.png');
  vertical-align: -2px;
}
.icon-change{
  width: 11px;
  height: 13px;
  display: inline-block;
  background: url('../../assets/icons/icon-change1.png');
  vertical-align: -2px;
}
.icon-renew{
  width: 12px;
  height: 12px;
  display: inline-block;
  background: url('../../assets/icons/icon-renew1.png');
  vertical-align: -2px;
}
::v-deep .el-dropdown-menu__item:hover{
  .icon-login{
    background: url('../../assets/icons/icon-login2.png');
  }
  .icon-change{
    background: url('../../assets/icons/icon-change2.png');
  }
  .icon-renew{
    background: url('../../assets/icons/icon-renew2.png');
  }
}
::v-deep .disabled{
  color: #bbb !important;
  background: #fff !important;
  cursor: no-drop;
  &.el-dropdown-menu__item:hover{
    .icon-login{
      background: url('../../assets/icons/icon-login1.png');
    }
  }
}
.relation-item{
  width: 49%;
  background: #F5F8FD;
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.c-red{
  color: #E14B38;
}
</style>